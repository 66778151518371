<template>
    <BaseTemplate app-name="Monitoramento" page-title="">
        <template #content>
            
            <div class="min-h-screen bg-slate-200 dark:bg-gray-900 transition-colors duration-300 p-2">
                <div class="container mx-auto max-w-[1920px]">
                    <div class="flex flex-col items-center justify-center mx-auto">
                        <!-- Imagem muda dinamicamente com base no estado do tema -->
                        <img :src=LogoDark alt="Logo" class="xl:h-22 sm:h-12 w-auto mb-8 animate-float" />
                    </div>
                                <!-- Mensagem de carregamento -->
            <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 text-gray-400 animate-spin mb-4" viewBox="0 0 24 24" fill="none">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                <p class="text-xl font-semibold text-gray-600 dark:text-gray-300">Carregando</p>
            </div>
                    <!-- Lista de Unidades Médicas com cores aprimoradas -->
                    <transition-group name="unit-list" tag="div"
                        class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-5 gap-4">
                        <div v-for="(unidade) in unidades" :key="unidade.id" :data-id="unidade.id" ref="unidadeRefs"
                            class="flex flex-col bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300 dark:border-gray-700 overflow-hidden transition-transform transform hover:scale-105 duration-300">
                            <!-- Cabeçalho com Nome da Unidade -->
                            <div
                                class="bg-blue-200 dark:bg-gray-700 xl:text-lg p-3 flex justify-between items-center text-gray-800 dark:text-white font-semibold uppercase tracking-wide">
                                <div class="truncate">
                                    {{ unidade.nome }}
                                </div>
                                <span :class="{
                                    'bg-green-500': unidade.status === 'em_funcionamento',
                                    'bg-yellow-500': unidade.status === 'em_atendimento',
                                    'bg-red-500': unidade.status === 'parado',
                                    'bg-orange-500': unidade.status === 'em_manutencao'
                                }"
                                    class="w-4 h-4 rounded-full border-2 border-white dark:border-gray-800 shadow-lg"></span>
                            </div>

                            <!-- Conteúdo do Card -->
                            <div class="p-3 space-y-3 bg-gray-50 dark:bg-gray-900 rounded-b-lg">

                                <!-- Contingência da Unidade -->
                                <div v-if="unidade.equipamentos.every(equipamento => ['pendente', 'em_atendimento'].includes(equipamento.status))"
                                    class="bg-red-500 dark:bg-red-900 p-3 rounded-md shadow-md border border-red-300 dark:border-red-800">
                                    <div class="flex items-center gap-3 mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            class="h-6 w-6 text-white dark:text-red-400 animate-pulse"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                        </svg>
                                        <h3 class="text-white dark:text-red-300 font-bold uppercase tracking-wider">
                                            Unidade Parada
                                        </h3>
                                    </div>
                                    <p class="text-sm text-white dark:text-gray-300 font-medium mb-2">
                                        Opções de Contingência:
                                    </p>
                                    <ul class="list-disc pl-5 space-y-1 text-sm text-blue-300 dark:text-blue-300">
                                        <!-- Verifica se há contingências disponíveis -->
                                        <template v-if="getContingencias(unidade.nome).length > 0">
                                            <li v-for="contingencia in getContingencias(unidade.nome)"
                                                :key="contingencia.id"
                                                class="hover:text-blue-800 dark:hover:text-blue-200 transition-colors cursor-pointer">
                                                {{ contingencia.nome }}
                                            </li>
                                        </template>
                                        <!-- Exibe mensagem caso não haja contingências -->
                                        <template v-else>
                                            <li class="text-gray-500 dark:text-gray-400">Nenhuma contingência
                                                disponível.</li>
                                        </template>
                                    </ul>
                                </div>


                                <!-- Equipamentos -->
                                <div>
                                    <h3 class="text-sm font-bold text-gray-700 dark:text-gray-300 uppercase mb-1">
                                        Equipamentos
                                    </h3>
                                    <div
                                        class="space-y-1 max-h-24 overflow-y-auto text-xs bg-gray-100 dark:bg-gray-800 p-2 rounded-md">
                                        <div v-for="equipamento in unidade.equipamentos" :key="equipamento.id"
                                            class="flex justify-between items-center bg-gray-50 dark:bg-gray-700 text-gray-700 dark:text-gray-300  p-1 rounded-md">
                                            <span class="truncate">{{ equipamento.nome }}</span>
                                            <span :class="{
                                                'bg-green-500': equipamento.status === 'em_funcionamento',
                                                'bg-yellow-500': equipamento.status === 'pendente',
                                                'bg-red-500': equipamento.status === 'em_atendimento'
                                            }" class="w-3 h-3 rounded-full"></span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Eventos em Aberto -->
                                <div>

                                    <div v-if="unidade.eventos && unidade.eventos.length > 0"
                                        class="space-y-1 max-h-20 overflow-y-auto text-xs bg-gray-100 dark:bg-gray-800 p-2 rounded-md">
                                        <h3 class="text-sm font-bold text-gray-700 dark:text-gray-300 uppercase mb-1">
                                            Eventos em Aberto
                                        </h3>
                                        <div v-for="evento in unidade.eventos" :key="evento.id"
                                            @click="navegarParaEvento(evento.id)" :class="{
                                                'bg-yellow-500 dark:bg-yellow-800 text-black dark:text-yellow-200': evento.status === 'pendente',
                                                'bg-red-500 dark:bg-red-900 text-white dark:text-red-200': evento.status === 'em_atendimento',
                                            }"
                                            class="tracking-wider p-1 rounded-lg cursor-pointer hover:bg-opacity-90 transition-colors">
                                            {{ evento.equipamento_nome }} |
                                            {{ evento.motivo }} |
                                            ({{ evento.status === 'pendente'
                                                ? `Data Início: ${formatarData(evento.data_inicio)} | Data Final:
                                            ${formatarDataFinal(evento.data_previsao)}`
                                                : `Data: ${formatarData(evento.data_inicio)} | Data Final:
                                            ${formatarDataFinal(evento.data_final)}` }})
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>

                    <!-- Botão Flutuante para Unidade Parada (Mobile) -->
                    <button v-if="hasParada" @click="cycleToNextParada"
                        class="fixed bottom-20 right-5 z-50 flex flex-col items-center justify-center animate-pulse w-16 h-16 bg-red-600 text-white rounded-full shadow-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-circle-alert">
                            <circle cx="12" cy="12" r="10" />
                            <line x1="12" x2="12" y1="8" y2="12" />
                            <line x1="12" x2="12.01" y1="16" y2="16" />
                        </svg>
                        <span class="text-xs font-bold mt-1">{{ totalParadas }}</span>
                    </button>

                </div>
            </div>
            <!-- Rodapé fixo -->
            <p class="text-center text-sm mt-6 text-gray-500 dark:text-gray-400">
                © 2024 T.I CCDTI. Todos os direitos reservados.
            </p>
        </template>

    </BaseTemplate>

</template>

<script setup>
import BaseTemplate from './BaseTemplate.vue';

import LogoDark from '@/assets/LogoDark.png';
import { ref, onMounted, computed, onUnmounted} from 'vue';
import axios from 'axios';
import router from '@/router';

axios.defaults.headers.common['X-API-KEY'] = sessionStorage.api_token; // Substitua pela sua API Key real

const unidadeRefs = ref([]);
const hasParada = ref(false);
const isLoading = ref(true);
const unidades = ref([]);
const currentParadaIndex = ref(0); // Índice do problema atual


const contingencias = ref({
    "HM Miguel Couto": [
        "Centro de Imagem da Rocinha",
        "Centro de Diagnóstico CCDTI",
        "HM Lourenço Jorge"
    ],
    "HM Souza Aguiar": [
        "Centro de Diagnóstico CCDTI",
        "HM Salgado Filho",
        "HM Miguel Couto"
    ],
    "HM Salgado Filho": [
        "Policlínica Rodolpho Rocco",
        "Centro de Diagnóstico CCDTI",
        "HM Souza Aguiar"
    ],
    "HM Lourenço Jorge": [
        "UPA 24H Cidade de Deus",
        "HM Miguel Couto",
        "HM Souza Aguiar"
    ],
    "HM Ronaldo Gazolla": [
        "HM Francisco da Silva Telles",
        "UPA 24H Madureira",
        "HM da Piedade"
    ],
    "HM Francisco da Silva Telles": [
        "HM Ronaldo Gazolla",
        "HM da Piedade"
    ],
    "HM Rocha Faria": [
        "CMS Belizário Penna",
        "Policlínica Lincoln de Freitas"
    ],
    "UPA 24H Madureira": [
        "Policlínica Rodolpho Rocco",
        "HM Salgado Filho"
    ],
    "UPA 24H Cidade de Deus": [
        "HM Lourenço Jorge",
        "CF Otto Alves de Carvalho",
        "HM Miguel Couto"
    ],
    "HM da Piedade": [
        "UPA 24H Madureira",
        "Policlínica Rodolpho Rocco",
        "HM Salgado Filho"
    ],
    "Policlínica Rodolpho Rocco": [
        "HM Salgado Filho",
        "UPA 24H Madureira"
    ],
    "CF Otto Alves de Carvalho": ["UPA 24H Cidade de Deus"],

    "CF Harvey Ribeiro": ["Policlínica Lincoln de Freitas"],

    "Policlínica Manoel Guilherme": ["CMS Belizário Penna"],

    "Policlínica Lincoln de Freitas": ["CMS Belizário Penna"],
    "CF Adib Jatene": [
        "Policlínica Rodolpho Rocco",
        "Centro de Diagnóstico CCDTI"
    ],
    "Centro de Imagem da Rocinha": [
        "HM Miguel Couto",
        "SISREG"
    ],
    "CMS Belizário Penna": [
        "Policlínica Manoel Guilherme",
        "Policlínica Lincoln de Freitas"
    ],
    "HM Jesus": ["Centro de Diagnóstico CCDTI"]
});

// Buscar unidades do backend
async function fetchUnidades() {
    try {
        const response = await axios.get('/api/v1/monitoramento/unidades/');
        const fetchedUnidades = response.data;

        // Ordenar as unidades: primeiro com mais de 1 equipamento
        unidades.value = fetchedUnidades.sort((a, b) => b.equipamentos.length - a.equipamentos.length);

        // Verifica se há unidades paradas
        hasParada.value = unidades.value.some(unidade =>
            unidade.equipamentos.every(
                equipamento =>
                    equipamento.status === 'em_atendimento' || equipamento.status === 'pendente'
            )
        );
    } catch (error) {
        console.error('Erro ao carregar unidades:', error);
    } finally {
        isLoading.value = false;
    }
}

// Total de problemas
const totalParadas = computed(() =>
    unidades.value.filter(unidade =>
        unidade.equipamentos.every(
            equipamento =>
                equipamento.status === 'em_atendimento' || equipamento.status === 'parado'
        )
    ).length
);

// Rolagem para a unidade selecionada
function scrollToParada(unidadeId) {
    const targetElement = unidadeRefs.value.find(ref => ref?.dataset?.id === String(unidadeId));
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

// Ciclar para a próxima unidade parada
function cycleToNextParada() {
    const paradas = unidades.value.filter(unidade =>
        unidade.equipamentos.every(
            equipamento =>
                equipamento.status === 'em_atendimento' || equipamento.status === 'pendente'
        )
    );
    if (paradas.length > 0) {
        currentParadaIndex.value = (currentParadaIndex.value + 1) % paradas.length;
        const unidadeAtual = paradas[currentParadaIndex.value];
        scrollToParada(unidadeAtual.id);
    }
}

// Contingências de uma unidade
function getContingencias(unidadeNome) {
    // Localiza a unidade principal pelo nome
    const unidade = unidades.value.find(u => u.nome === unidadeNome);

    // Verifica se a unidade está em condições para verificar contingências
    if (
        unidade &&
        unidade.equipamentos.every(
            equipamento =>
                equipamento.status === 'em_atendimento' || equipamento.status === 'parado'
        )
    ) {
        // Obter as alternativas de contingência para a unidade
        const alternativas = contingencias.value[unidadeNome] || [];

        // Filtrar e retornar apenas unidades alternativas válidas
        return alternativas.map(nome => {
            const alternativa = unidades.value.find(u => u.nome === nome);

            // A unidade alternativa deve ter pelo menos um equipamento funcionando
            if (
                alternativa &&
                alternativa.equipamentos.some(equipamento => equipamento.status === 'em_funcionamento')
            ) {
                return alternativa;
            }

            return null; // Retorna null para alternativas inválidas
        }).filter(Boolean); // Remove unidades nulas
    }

    return []; // Caso a unidade não atenda aos critérios, retorna uma lista vazia
}
// Navegar para o evento
function navegarParaEvento(eventoId) {
    router.push(`/eventos/${eventoId}`);
}


// Formatar data no formato dd/MM/yyyy
function formatarData(data) {
    if (!data) return "Não informado";

    // Interpretar a string de data corretamente como UTC
    const date = new Date(data);

    // Garantir que a data seja ajustada corretamente para o fuso horário local
    const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return adjustedDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

// Formatar data no formato dd/MM/yyyy
function formatarDataFinal(data_final) {
    if (!data_final) return "Sem Previsão";

    // Interpretar a string de data corretamente como UTC
    const date = new Date(data_final);

    // Garantir que a data seja ajustada corretamente para o fuso horário local
    const adjustedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    return adjustedDate.toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
}

let intervalId;

// Atualizar refs ao montar
onMounted(() => {
    unidadeRefs.value = [];
    fetchUnidades();
});

onMounted(() => {
    // Carregar dados inicialmente
    fetchUnidades();

    // Atualizar dados a cada 1 minuto
    intervalId = setInterval(() => {
        fetchUnidades();
    }, 60000); // 60.000 ms = 1 minuto
});

onUnmounted(() => {
    // Limpar o intervalo ao desmontar o componente
    if (intervalId) {
        clearInterval(intervalId);
    }
});

</script>

<style>
/* Estilo padrão da scrollbar */
::-webkit-scrollbar {
    width: 8px; /* Largura da scrollbar */
    height: 8px; /* Altura da scrollbar horizontal */
}

/* Estilo do trilho da scrollbar */
::-webkit-scrollbar-track {
    background: #f4f4f4; /* Cor de fundo do trilho */
    border-radius: 10px; /* Bordas arredondadas */
}

/* Estilo do polegar da scrollbar */
::-webkit-scrollbar-thumb {
    background: #888; /* Cor do polegar */
    border-radius: 10px; /* Bordas arredondadas */
}

/* Hover no polegar da scrollbar */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Cor do polegar ao passar o mouse */
}

/* Estilização em modo escuro */
body.dark ::-webkit-scrollbar-track {
    background: #2d2d2d; /* Cor do trilho no modo escuro */
}

body.dark ::-webkit-scrollbar-thumb {
    background: #555; /* Cor do polegar no modo escuro */
}

body.dark ::-webkit-scrollbar-thumb:hover {
    background: #888; /* Cor do polegar ao passar o mouse no modo escuro */
}

</style>
