<template>
  <div class="flex h-screen bg-gray-100 dark:bg-gray-900">
    <!-- Sidebar -->
    <aside :class="[
      'transition-all duration-300 ease-out',
      'bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200',
      'fixed inset-y-0 left-0 z-30 flex flex-col',
      'border-r border-gray-200 dark:border-gray-700',
      { 'w-20': isCollapsed, 'w-64': !isCollapsed },
      isSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'
    ]">
      <!-- Sidebar Header -->
      <div class="flex-none px-4 py-4 border-b border-gray-200 dark:border-gray-700">
        <div class="flex items-center">
          <button @click="toggleSidebar"
            class="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors hidden md:block"
            :class="{ 'ml-0': isCollapsed, 'mr-3': !isCollapsed }">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 transition-transform duration-300"
              :class="{ 'rotate-180': isCollapsed }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
          <div class="overflow-hidden transition-all duration-300 ease-out"
            :class="{ 'w-0 opacity-0': isCollapsed, 'w-32 opacity-100': !isCollapsed }">
            <h1 class="font-bold text-base whitespace-nowrap">{{ appName }}</h1>
          </div>
        </div>
      </div>



      <!-- Navigation -->
      <div class="flex-1 overflow-hidden hover:overflow-y-auto">
        <nav class="p-4 space-y-2">
          <a v-for="item in navItems" :key="item.name" :href="item.href" :class="[
            'flex items-center rounded-lg transition-all duration-200',
            'hover:bg-gray-100 dark:hover:bg-gray-700',
            'p-3 group',
            isCollapsed ? 'justify-center' : 'space-x-3',
            isActive(item.href) ? 'bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100' : ''
          ]" :title="isCollapsed ? item.name : ''">
            <component :is="item.icon" class="h-5 w-5 flex-shrink-0 transition-transform duration-200"
              :class="{ 'group-hover:scale-110': isCollapsed }" />
            <div class="overflow-hidden transition-all duration-300 ease-out"
              :class="{ 'w-0 opacity-0': isCollapsed, 'w-40 opacity-100': !isCollapsed }">
              <span class="whitespace-nowrap">{{ item.name }}</span>
            </div>
          </a>
        </nav>
      </div>

      <!-- Notifications 
      <div class="flex-none px-4 py-1 border-t border-gray-200 dark:border-gray-700 text-gray-800 font-bold">
        <button @click="toggleNotificationMenu" :class="[
          'w-full rounded-lg transition-all duration-200',
          'hover:bg-gray-100 dark:hover:bg-gray-700',
          'flex items-center relative p-3 group',
          isCollapsed ? 'justify-center' : 'justify-start'
        ]" :title="isCollapsed ? 'Notifications' : ''" data-notification-menu>
          <div class="relative flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-gray-800 dark:text-gray-200 group-hover:text-blue-500 dark:group-hover:text-blue-300 transition-colors duration-200"
              fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.006 6.006 0 00-4.593-5.812c-.375-1.045-1.37-1.77-2.407-1.77s-2.032.725-2.407 1.77A6.006 6.006 0 006 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h16z" />
            </svg>
            <span v-if="notifications.length" class="absolute -top-1 -right-1 h-2 w-2 bg-red-500 rounded-full"></span>
          </div>
          <div class="overflow-hidden transition-all duration-300 ease-out"
            :class="{ 'w-0 opacity-0': isCollapsed, 'w-auto opacity-100 ml-3': !isCollapsed }">
            <span class="font-medium text-gray-800 dark:text-gray-200">Notificações</span>
          </div>
        </button>
      </div>-->

      <!-- User Profile -->
      <div class="flex-none px-4 py-4" data-user-menu>
        <div @click="toggleUserMenu" :class="[
          'flex items-center rounded-lg cursor-pointer transition-all duration-200',
          'hover:bg-gray-100 dark:hover:bg-gray-700 p-2',
          isCollapsed ? 'justify-center' : 'space-x-3'
        ]" data-user-menu-button>
          <div v-if="!userAvatar" :class="[
            'h-10 w-10 rounded-full font-bold flex items-center justify-center flex-shrink-0 transition-transform duration-200',
            'bg-gray-300 text-gray-800 dark:bg-gray-600 dark:text-gray-200',
            isCollapsed ? 'hover:scale-110' : ''
          ]">
            {{ getUserInitials }}
          </div>
          <img v-else :src="userAvatar" :alt="getUserName"
            class="h-10 w-10 rounded-full object-cover flex-shrink-0 transition-transform duration-200"
            :class="{ 'hover:scale-110': isCollapsed }" />
          <div class="overflow-hidden transition-all duration-300 ease-out"
            :class="{ 'w-0 opacity-0': isCollapsed, 'w-auto opacity-100 ml-3': !isCollapsed }">
            <div class="flex items-center whitespace-nowrap">
              <span class="font-medium text-gray-800 dark:text-gray-200">{{ getUserName }}</span>
              <component :is="isUserMenuOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="h-4 w-4 ml-2 flex-shrink-0 text-gray-800 dark:text-gray-200" />
            </div>
          </div>
        </div>
      </div>
    </aside>

    <!-- Main Content -->
    <div :class="[
      'flex-1 flex flex-col',
      'transition-all duration-300 ease-in-out bg-slate-200 dark:bg-gray-900',
      isCollapsed ? 'md:ml-16' : 'md:ml-64',
      'pb-20 md:pb-0' // Extra padding for mobile footer
    ]">
      <!-- Top Navigation Bar -->
      <header class="shadow-md">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <h2 class="text-xl font-semibold text-gray-800 dark:text-white">
            {{ pageTitle }}
          </h2>
        </div>
      </header>

      <!-- Main Content Area -->
      <main class="flex-1 p-4 bg-slate-200 dark:bg-gray-900 pb-20">
        <slot name="content">
          <div class="text-center text-gray-500 dark:text-gray-400">
            Nada para mostrar :(
          </div>
        </slot>
      </main>
    </div>

    <!-- Mobile Footer Navigation -->
    <nav
      class="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 md:hidden z-40">
      <div ref="navContainer"
        class="relative pb-2 flex w-full overflow-x-auto scrollbar-hidden bg-white/90 dark:bg-gray-800/90 backdrop-blur-md shadow-[0_-2px_10px_rgba(0,0,0,0.05)]">

        <!-- Links de navegação -->
        <a v-for="item in visibleNavItems" :key="item.name" :href="item.href" :class="[
          'flex flex-col items-center justify-center flex-grow px-4 py-3 transition-all duration-200',
          isActive(item.href)
            ? 'bg-blue-50/70 dark:bg-blue-900/50 text-blue-600 dark:text-blue-300'
            : 'text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700',
          'active:scale-95'
        ]" :title="item.name">
          <div class="relative">
            <component :is="item.icon" class="h-6 w-6 mb-1 transition-transform"
              :class="isActive(item.href) ? 'scale-110' : ''" />
            <span v-if="isActive(item.href)"
              class="absolute -top-1 -right-1 h-2 w-2 bg-blue-500 rounded-full animate-ping"></span>
          </div>
          <span class="text-xs font-medium tracking-tight">
            {{ item.name }}
          </span>
        </a>

        <!-- Botão Usuário -->
        <button @click="toggleMobileUserMenu" data-user-menu-mobile
          class="flex flex-col items-center justify-center flex-grow px-4 py-3 transition-all duration-200 relative group active:scale-95"
          title="Usuário" data-user-menu-mobile-button>
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" :class="[
              'stroke-current transition-colors duration-200',
              isDark ? 'text-gray-200 group-hover:text-blue-300' : 'text-gray-500 group-hover:text-blue-500'
            ]" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-menu">
              <line x1="4" x2="20" y1="12" y2="12" />
              <line x1="4" x2="20" y1="6" y2="6" />
              <line x1="4" x2="20" y1="18" y2="18" />
            </svg>
          </div>
          <span
            class="text-xs font-medium text-gray-500 dark:text-gray-400 group-hover:text-blue-500 dark:group-hover:text-blue-300 tracking-tight">
            Usuário
          </span>
        </button>

        <!-- Indicadores de rolagem -->
        <div v-if="showLeftArrow"
          class="absolute top-0 left-0 bottom-0 w-12 bg-gradient-to-r from-white dark:from-gray-800 pointer-events-none flex items-center justify-center z-10">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
        <div v-if="showRightArrow"
          class="absolute top-0 right-0 bottom-0 w-12 bg-gradient-to-l from-white dark:from-gray-800 pointer-events-none flex items-center justify-center z-10">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      </div>
    </nav>





    <!-- Notifications Menu -->
    <div class="relative" ref="notificationMenu">
      <transition name="slide-from-avatar">
        <div v-if="isNotificationMenuOpen"
          class="fixed bottom-24 left-20 py-2 px-4 w-64 bg-blue-500 text-white rounded-lg shadow-xl z-[9999] animate-fade-in-up">
          <div class="relative">
            <div
              class="absolute -bottom-[5px] -left-[28px] w-0 h-0 border-t-[13px] rotate-90 border-t-blue-500 border-l-[8px] border-l-transparent border-r-[8px] border-r-transparent">
            </div>

            <h3 class="text-sm font-semibold mb-2">Notificações</h3>
            <ul class="divide-y divide-blue-400">
              <li v-for="notification in notifications" :key="notification.id" class="py-2 text-sm text-blue-100">
                {{ notification.text }}
              </li>
            </ul>
            <p v-if="!hasNotifications" class="text-center text-blue-200">
              Sem notificações
            </p>
          </div>
        </div>
      </transition>
    </div>

    <!-- Mobile User Menu -->
    <div class="relative" ref="mobileUserMenu">
      <transition name="slide-from-avatar">
        <div v-if="isMobileUserMenuOpen"
          class="fixed bottom-16 left-4 right-4 py-4 px-6 bg-white dark:bg-gray-800 rounded-2xl shadow-2xl z-[9999] ring-2 ring-blue-100 dark:ring-gray-700 border border-gray-100 dark:border-gray-700" >
          <div class="flex flex-col space-y-4">
            <button @click="toggleDarkMode"
              class="w-full flex items-center justify-between px-4 py-3 text-sm font-semibold text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-600 transition-all duration-300 ease-in-out">
              <span class="flex items-center">
                <component :is="isDark ? SunIcon : MoonIcon" class="h-5 w-5 mr-3 text-gray-500 dark:text-gray-300" />
                {{ isDark ? "Modo Claro" : "Modo Escuro" }}
              </span>
            </button>
            

            <button @click="openPasswordChangeModal"
              class="w-full flex items-center justify-between px-4 py-3 text-sm font-semibold text-blue-700 dark:text-blue-300 bg-blue-50 dark:bg-blue-900/30 rounded-xl hover:bg-blue-100 dark:hover:bg-blue-800 transition-all duration-300 ease-in-out">
              <span class="flex items-center">
                <Lock class="h-5 w-5 mr-3 text-blue-600 dark:text-blue-400" />
                Trocar Senha
              </span>
            </button>

            <button @click="handleLogout"
              class="w-full flex items-center justify-between px-4 py-3 text-sm font-semibold text-red-700 dark:text-red-300 bg-red-50 dark:bg-red-900/30 rounded-xl hover:bg-red-100 dark:hover:bg-red-800 transition-all duration-300 ease-in-out">
              <span class="flex items-center">
                <LogOut class="h-5 w-5 mr-3 text-red-600 dark:text-red-400" />
                Logout
              </span>
            </button>
          </div>
        </div>
      </transition>
    </div>

    <!-- User Menu Dropdown -->
    <div class="relative" ref="userMenu">
      <transition name="slide-from-avatar">
        <div v-if="isUserMenuOpen"
          class="fixed bottom-8 left-20 py-4 px-2 w-72 bg-white dark:bg-gray-800 text-gray-800 dark:text-white rounded-2xl shadow-2xl z-[9999] animate-fade-in-up ring-2 ring-blue-100 dark:ring-gray-700 border border-gray-100 dark:border-gray-700">
          <div class="relative">
            <div
              class="absolute -bottom-[5px] -left-[28px] w-0 h-0 border-t-[13px] rotate-90 border-t-white dark:border-t-gray-800 border-l-[8px] border-l-transparent border-r-[8px] border-r-transparent">
            </div>

            <div class="space-y-1">
              <button @click="toggleDarkMode"
                class="block w-full text-left px-4 py-3 text-sm font-semibold hover:bg-gray-100 dark:hover:bg-gray-700 rounded-xl transition-all duration-300 ease-in-out flex items-center justify-between">
                <span class="flex items-center">
                  <component :is="isDark ? SunIcon : MoonIcon"
                    class="inline-block mr-3 h-5 w-5 text-gray-500 dark:text-gray-300" />
                  {{ isDark ? "Modo Claro" : "Modo Escuro" }}
                </span>
              </button>

              <button @click="openPasswordChangeModal"
                class="block w-full text-left px-4 py-3 text-sm font-semibold text-blue-700 dark:text-blue-300 hover:bg-blue-50 dark:hover:bg-blue-800 rounded-xl transition-all duration-300 ease-in-out flex items-center justify-between">
                <span class="flex items-center">
                  <Lock class="inline-block mr-3 h-5 w-5 text-blue-600 dark:text-blue-400" />
                  Trocar Senha
                </span>
              </button>

              <button @click="handleLogout"
                class="block w-full text-left px-4 py-3 text-sm font-semibold text-red-700 dark:text-red-300 hover:bg-red-50 dark:hover:bg-red-800 rounded-xl transition-all duration-300 ease-in-out flex items-center justify-between">
                <span class="flex items-center">
                  <LogOut class="inline-block mr-3 h-5 w-5 text-red-600 dark:text-red-400" />
                  Logout
                </span>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <transition name="fade">
      <div v-if="isPasswordChangeModalOpen"
        class="fixed inset-0 z-[10000] flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md p-6 mx-5">
          <h2 class="text-xl font-bold mb-4 text-gray-800 dark:text-gray-200">Trocar Senha</h2>

          <!-- Mensagem de Sucesso -->
          <div v-if="successMessage" class="mb-4 p-4 text-green-700 bg-green-100 border-l-4 border-green-500 rounded">
            {{ successMessage }}
          </div>

          <!-- Mensagem de Erro -->
          <div v-if="errorMessage" class="mb-4 p-4 text-red-700 bg-red-100 border-l-4 border-red-500 rounded">
            {{ errorMessage }}
          </div>

          <form @submit.prevent="handleChangePassword">
            <div class="mb-4">
              <label for="current-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Senha
                Atual</label>
              <input v-model="currentPassword" type="password" id="current-password" required
                class="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500" />
            </div>
            <div class="mb-4">
              <label for="new-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Nova
                Senha</label>
              <input v-model="newPassword" type="password" id="new-password" required
                class="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500" />
            </div>
            <div class="mb-4">
              <label for="confirm-password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Confirme
                a Nova Senha</label>
              <input v-model="confirmPassword" type="password" id="confirm-password" required
                class="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 focus:ring-blue-500 focus:border-blue-500" />
            </div>
            <div class="flex justify-end space-x-4">
              <button @click="closePasswordChangeModal" type="button"
                class="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-600 rounded-md hover:bg-gray-300 dark:hover:bg-gray-700">
                Cancelar
              </button>
              <button type="submit"
                class="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>


  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref, onMounted, computed, onBeforeUnmount, defineProps, inject, watch } from 'vue';
import {
  FileText,
  Hospital,
  LogOut,
  Lock,
  SunIcon,
  MoonIcon,
  MessageCircle

} from 'lucide-vue-next';
import axios from 'axios';

const props = defineProps({
  appName: {
    type: String,
    default: 'Dashboard CCDTI'
  },
  pageTitle: {
    type: String,
    default: 'Dashboard'
  },
  userAvatar: {
    type: String,
    default: null
  },
  customNavItems: {
    type: Array,
    default: () => []
  }
});


const router = useRouter();

const isDark = ref(inject('isDark', false));
const isUserMenuOpen = ref(false);
const isMobileUserMenuOpen = ref(false);
const isNotificationMenuOpen = ref(false);
const isSidebarOpen = ref(false);
const isCollapsed = ref(true);
const userType = ref('admin'); // Exemplos: 'admin', 'manager', 'user', etc.
const notifications = ref([]);

const isPasswordChangeModalOpen = ref(false);
const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const errorMessage = ref('');
const successMessage = ref('');


const getSessionStorageItem = (key) => {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(key);
  }
  return null;
};



const firstname = ref(getSessionStorageItem('firstname') || 'Usuário');
const lastname = ref(getSessionStorageItem('lastname') || 'Genérico');


const getUserName = computed(() => {
  return `${firstname.value} ${lastname.value}`;
});

const openPasswordChangeModal = () => {
  isPasswordChangeModalOpen.value = true;
};

const closePasswordChangeModal = () => {
  isPasswordChangeModalOpen.value = false;
  currentPassword.value = '';
  newPassword.value = '';
  confirmPassword.value = '';
  errorMessage.value = '';
  successMessage.value = '';
};

const handleChangePassword = async () => {
  // Limpa mensagens de erro e sucesso antes de executar
  errorMessage.value = '';
  successMessage.value = '';

  // Verifica se as senhas coincidem
  if (newPassword.value !== confirmPassword.value) {
    errorMessage.value = 'As senhas não coincidem.';
    return;
  }

  // Verifica se os campos estão preenchidos
  if (!currentPassword.value || !newPassword.value || !confirmPassword.value) {
    errorMessage.value = 'Por favor, preencha todos os campos.';
    return;
  }

  try {
    // Realiza a requisição para a API de alteração de senha
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/api/v1/change-password/`,
      {
        current_password: currentPassword.value,
        new_password: newPassword.value,
      },
      {
        headers: {
          Authorization: `Token ${sessionStorage.getItem('api_token')}`, // Inclui o token de autenticação
        },
      }
    );

    // Exibe mensagem de sucesso
    successMessage.value = response.data.detail || 'Senha alterada com sucesso.';
    setTimeout(() => {
      closePasswordChangeModal(); // Fecha o modal após a alteração
    }, 2000);
  } catch (error) {
    // Lida com erros retornados pela API
    if (error.response?.status === 400) {
      errorMessage.value = error.response.data.detail || 'Erro ao alterar a senha.';
    } else if (error.response?.status === 401) {
      errorMessage.value = 'Sessão expirada. Por favor, faça login novamente.';
    } else {
      errorMessage.value = 'Erro inesperado ao alterar a senha. Tente novamente mais tarde.';
    }
  }
};


// Computed para iniciais
const getUserInitials = computed(() => {
  const first = firstname.value.charAt(0).toUpperCase();
  const last = lastname.value.charAt(0).toUpperCase();
  return `${first}${last}`;
});




// Acesso seguro em computeds ou templates
const hasNotifications = computed(() => notifications.value?.length > 0);

// Simulação de preenchimento de notificações para teste
onMounted(() => {
  notifications.value = [
    { id: 1, text: 'Nova mensagem recebida' },
    { id: 2, text: 'Atualização de sistema disponível' },
  ];
});


const isActive = (href) => {
  return router.currentRoute.value.path === href;
};

// Recuperando grupos de usuário do sessionStorage
const userGroups = computed(() => {
  const groups = sessionStorage.getItem('user_groups');
  return groups ? JSON.parse(groups) : [];  // Retorna os grupos ou um array vazio
});

// Computando os itens de navegação com base nos grupos do usuário
const navItems = computed(() => {
  const groups = userGroups.value; // Recupera os grupos do usuário de forma reativa

  return props.customNavItems.length > 0
    ? props.customNavItems
    : [
        {
          name: 'Eventos',
          icon: FileText,
          href: '/eventos',
          current: false,
          visibleFor: ['manager', 'admin'], // Defina os tipos de usuários permitidos
        },
        {
          name: 'Dashboard',
          icon: Hospital,
          href: '/dashboardequipamentos',
          current: false,

        },
        {
          name: 'Instâncias',
          icon: MessageCircle,
          href: '/dashboardinstancias',
          current: false,
          visibleFor: ['manager', 'admin'],
        },
      ].filter(item => {
        // Filtra os itens com base nos grupos do usuário
        if (item.visibleFor) {
          return item.visibleFor.some(group => groups.includes(group)); // Exibe o item se o usuário estiver no grupo permitido
        }
        // Caso contrário, o item é visível para todos
        return true;
      });
});




const visibleNavItems = computed(() => {
  return navItems.value.filter(item => {
    // Exibe todos os itens se a propriedade `visibleFor` não existir
    if (!item.visibleFor) return true;
    // Verifica se o tipo de usuário está permitido
    return item.visibleFor.includes(userType.value);
  });
});

// Atualiza a variável CSS dinamicamente
onMounted(() => {
  document.documentElement.style.setProperty('--visible-items', visibleNavItems.value.length);
});
watch(visibleNavItems, (newItems) => {
  document.documentElement.style.setProperty('--visible-items', newItems.length);
});


const toggleSidebar = () => {
  if (window.innerWidth >= 768) {
    isCollapsed.value = !isCollapsed.value;
  } else {
    isSidebarOpen.value = !isSidebarOpen.value;
  }
};

// Função para alternar o modo escuro/claro
const toggleDarkMode = () => {
  isDark.value = !isDark.value;
  localStorage.setItem('darkMode', isDark.value);
  if (isDark.value) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

onMounted(() => {
  const darkMode = localStorage.getItem('darkMode') === 'true';
  if (darkMode) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
});

const handleLogout = () => {
  sessionStorage.removeItem('api_token');
  sessionStorage.removeItem('token_expiry');
  sessionStorage.removeItem('username');
  sessionStorage.removeItem('firstname');
  sessionStorage.removeItem('lastname');
  sessionStorage.removeItem('user_groups');
  router.push({ name: 'Login' });
};


// Detectar clique fora do menu
const handleClickOutside = (event) => {
  // Fecha o menu de usuário ao clicar fora
  if (
    isUserMenuOpen.value &&
    !event.target.closest('[data-user-menu]')
  ) {
    isUserMenuOpen.value = false;
  }

  // Fecha o menu de notificações ao clicar fora
  if (
    isNotificationMenuOpen.value &&
    !event.target.closest('[data-notification-menu]')
  ) {
    isNotificationMenuOpen.value = false;
  }
};

// Detectar clique fora do menu
const handleClickOutsideMobile = (event) => {
  // Fecha o menu de usuário ao clicar fora
  if (
    isMobileUserMenuOpen.value &&
    !event.target.closest('[data-user-menu-mobile]')
  ) {
    isMobileUserMenuOpen.value = false;
  }

};

const toggleMobileUserMenu = () => {
  isMobileUserMenuOpen.value = !isMobileUserMenuOpen.value;
};

// Alternar menu de usuário
const toggleUserMenu = () => {
  isUserMenuOpen.value = !isUserMenuOpen.value;
  if (isUserMenuOpen.value) {
    isNotificationMenuOpen.value = false; // Fecha notificações se abertas
  }
};

// Alternar menu de notificações
//const toggleNotificationMenu = () => {
//  isNotificationMenuOpen.value = !isNotificationMenuOpen.value;
//  if (isNotificationMenuOpen.value) {
//    isUserMenuOpen.value = false; // Fecha menu de usuário se aberto
//  }
//};


// Registrar eventos de clique
onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

// Registrar eventos de clique
onMounted(() => {
  document.title = 'Dashboard - CCDTI'
  document.addEventListener('click', handleClickOutsideMobile);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutsideMobile);
});



const navContainer = ref(null);
const showLeftArrow = ref(false);
const showRightArrow = ref(false);

// Função para verificar a posição do scroll
const checkScrollPosition = () => {
  if (navContainer.value) {
    const { scrollLeft, scrollWidth, clientWidth } = navContainer.value;
    showLeftArrow.value = scrollLeft > 0; // Exibe seta esquerda se não está no início
    showRightArrow.value = scrollLeft + clientWidth < scrollWidth; // Exibe seta direita se não está no final
  }
};

// Adiciona evento de scroll e verifica na montagem
onMounted(() => {
  checkScrollPosition();
  if (navContainer.value) {
    navContainer.value.addEventListener('scroll', checkScrollPosition);
  }
  window.addEventListener('resize', checkScrollPosition); // Recalcula em mudanças de tamanho
});

// Remove eventos na desmontagem
onBeforeUnmount(() => {
  if (navContainer.value) {
    navContainer.value.removeEventListener('scroll', checkScrollPosition);
  }
  window.removeEventListener('resize', checkScrollPosition);
});

</script>


<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply antialiased;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes bounceX {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-4px);
  }
}

.animate-bounce-x {
  animation: bounceX 1.5s infinite;
}

/* Remove barra de rolagem visível */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

/* Gradiente nas setas de rolagem */
.bg-gradient-to-r {
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.bg-gradient-to-l {
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.sidebar-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari and Opera */
}

.fade-in {
  animation: fadeIn 0.5s ease-out;
}

.default-avatar {
  background-color: #e5e7eb;
  /* Cor de fundo do avatar genérico */
  color: #6b7280;
  /* Cor do texto no avatar genérico */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.775rem;
  font-weight: 700;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

img.rounded-full,
div.rounded-full {
  height: 2rem;
  /* Altura fixa para recolhido */
  width: 2rem;
  /* Largura fixa para recolhido */
}


@media (min-width: 768px) {

  img.rounded-full:not(.h-10),
  div.rounded-full:not(.h-10) {
    height: 2rem;
    /* Altura para expandido */
    width: 2rem;
    /* Largura para expandido */
  }
}


/* Animação para o balão */
.slide-from-avatar-enter-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.slide-from-avatar-leave-active {
  transition: transform 0.3s ease-in, opacity 0.2s ease-in;
}

.slide-from-avatar-enter-from {
  transform: scale(0.8) translateY(20px);
  opacity: 0;
}

.slide-from-avatar-enter-to {
  transform: scale(1) translateY(0);
  opacity: 1;
}

.slide-from-avatar-leave-from {
  transform: scale(1);
  opacity: 1;
}

.slide-from-avatar-leave-to {
  transform: scale(0.8) translateY(20px);
  opacity: 0;
}

/* Menu Usuário */

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.3s ease-out;
}

button {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
</style>