<template>
  <div 
    class="flex justify-center items-center h-screen transition-colors duration-300"
    :class="isDarkMode ? 'bg-neutral-800' : 'bg-white'"
  >
    <div 
      class="text-center p-10 rounded-2xl  w-full max-w-md transition-all duration-300 ease-in-out hover:scale-[1.02]"
      :class="isDarkMode ? 'bg-neutral-800' : 'bg-white'"
    >
      <div class="flex justify-center mb-5 opacity-80">
        <Unplug 
          :size="100" 
          :color="iconColor" 
          class="text-red-500"
        />
      </div>
      
      <h1 
        class="text-3xl font-bold mb-4 transition-colors duration-300"
        :class="isDarkMode ? 'text-neutral-200' : 'text-neutral-800'"
      >
        Sem Conexão
      </h1>
      
      <p 
        class="text-base mb-6 leading-relaxed transition-colors duration-300"
        :class="isDarkMode ? 'text-neutral-400' : 'text-neutral-600'"
      >
        Por favor, verifique sua conexão com a internet e tente novamente.
      </p>
      
      <div class="flex justify-center mb-4">
        <button 
          @click="retryConnection"
          class="flex items-center gap-2 px-5 py-3 rounded-lg text-white font-semibold transition-all duration-300 ease-in-out hover:scale-[1.05]"
          :class="isDarkMode 
            ? 'bg-blue-700 hover:bg-blue-600' 
            : 'bg-blue-600 hover:bg-blue-700'"
        >
          Tentar Novamente
          <RefreshCw :size="20" />
        </button>
      </div>
      
      <p 
        v-if="errorMessage" 
        class="flex items-center p-4 mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-300 rounded">
        {{ errorMessage }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Unplug, RefreshCw } from 'lucide-vue-next';
import { useRouter, useRoute } from 'vue-router';

const isDarkMode = ref(false);
const router = useRouter();
const route = useRoute();

const iconColor = computed(() => {
  return isDarkMode.value ? '#ff4d4d' : '#dc3545';
});

const errorMessage = ref('');

const retryConnection = () => {
  if (navigator.onLine) {
    const redirect = route.query.redirect || '/';
    router.push(redirect);
  } else {
    errorMessage.value = 'Ainda sem conexão. Tente novamente.';
  }
};

const loadSavedTheme = () => {
  const savedTheme = localStorage.getItem('darkMode');
  if (savedTheme) {
    isDarkMode.value = savedTheme === 'true';
  }
};

loadSavedTheme();
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
