<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900">
      <!-- Botão para voltar -->
      <button
        @click="$router.back()"
        class="absolute top-4 left-4 px-4 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-700"
      >
        Voltar
      </button>
  
      <!-- Iframe de métricas -->
      <iframe
        v-if="iframeUrl"
        :src="iframeUrl"
        frameborder="0"
        class="w-full h-[90vh] border-none rounded"
        allowfullscreen
      ></iframe>
  
      <!-- Mensagem de erro -->
      <div v-else class="text-red-500 text-lg font-semibold">
        Não foi possível carregar as métricas. Tente novamente mais tarde.
      </div>
    </div>
  </template>
  
  <script setup>
  import { useRoute } from 'vue-router';
  import jwt from 'jsonwebtoken'; // Certifique-se de que o pacote está instalado: npm install jsonwebtoken
  
  // Obter os parâmetros da rota
  const route = useRoute();
  const id = route.params.id; // ID da unidade passado na rota
  
  // URLs e Chaves do Metabase
  const METABASE_SITE_URL = 'https://metabases.ccdti.com.br';
  const METABASE_SECRET_KEY =
    '9f7d6541297c0f1953069bff4dda6246421b66e26d53f08b719b3b45fde248c7';
  
  // Estado reativo para o iframe URL
  let iframeUrl = '';
  
  // Função para gerar o URL do iframe
  function generateIframeUrl() {
    try {
      const payload = {
        resource: { dashboard: 3 },
        params: {
          nome_unidade: [id], // Utilize o `id` recebido na rota
        },
        exp: Math.round(Date.now() / 1000) + 10 * 60, // Expira em 10 minutos
      };
  
      const token = jwt.sign(payload, METABASE_SECRET_KEY);
  
      // Construir URL do iframe
      iframeUrl = `${METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
    } catch (error) {
      console.error('Erro ao gerar o URL do iframe:', error.message);
      iframeUrl = ''; // Resetar URL em caso de erro
    }
  }
  
  // Inicializar a geração do iframe
  generateIframeUrl();
  </script>
  
  <style scoped>
  /* Estilo adicional para exibição */
  </style>
  