import './assets/tailwind.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker'; // Se o Vue CLI já criou o Service Worker automaticamente

const app = createApp(App);

// Sincronizar o estado inicial do tema com o armazenamento local
const darkMode = localStorage.getItem('darkMode') === 'true';
if (darkMode) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

// Passar o estado inicial para o aplicativo
app.provide('isDark', darkMode);

app.use(router);
app.mount('#app');
createApp(App).use(router).mount('#app');


// Registro do Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js');
}