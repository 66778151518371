<template>
    <BaseTemplate app-name="Eventos" page-title="">
        <template #content>
            <div class="min-h-screen bg-slate-200 dark:bg-gray-900 transition-colors duration-300">
                <div class="container mx-auto px-4 py-6 md:px-6 lg:px-8">
                    <!-- Header -->
                    <div class="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
                        <div class="flex items-center space-x-4">
                            <button @click="toggleDarkMode"
                                class="text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 p-2 rounded-full transition-colors"
                                aria-label="Toggle Dark Mode">
                                <sun-icon v-if="darkMode" class="h-6 w-6" />
                                <moon-icon v-else class="h-6 w-6" />
                            </button>
                        </div>
                        <button @click="openCreateModal"
                            class="w-full md:w-auto bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors dark:bg-blue-600 dark:hover:bg-blue-700">
                            Novo Evento
                        </button>
                    </div>

                    <!-- Eventos Table or Cards -->
                    <div v-if="eventos.length > 0">
                        <!-- Tabela para telas maiores -->
                        <div class="hidden lg:block bg-white dark:bg-gray-800 shadow-md rounded-lg overflow-x-auto">
                            <table class="w-full">
                                <thead class="bg-blue-200 dark:bg-gray-700">
                                    <tr>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            ID
                                        </th>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            Unidade
                                        </th>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            Categoria
                                        </th>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            Data Abertura
                                        </th>
                                        <th
                                            class="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                            Ações
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                    <tr v-for="evento in eventos" :key="evento.id"
                                        class="hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                                        <td class="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">{{ evento.id }}
                                        </td>
                                        <td class="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">{{
                                            evento.unidade_nome }}</td>
                                        <td class="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                                            {{ formatarTexto('categoria', evento.categoria_evento) }}
                                        </td>
                                        <td class="px-4 py-3">
                                            <span :class="{
                                                'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': formatarTexto('status', evento.status) === 'Finalizado',
                                                'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': formatarTexto('status', evento.status) === 'Pendente',
                                                'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': formatarTexto('status', evento.status) === 'Em Atendimento'
                                            }" class="px-3 py-1 rounded-full text-xs font-medium">
                                                {{ formatarTexto('status', evento.status) }}
                                            </span>
                                        </td>
                                        <td class="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                                            {{ formatarData(evento.data_abertura) }}
                                        </td>
                                        <td class="px-4 py-3">
                                            <button
                                                @click="$router.push({ name: 'DetalhesEvento', params: { id: evento.id } })"
                                                class="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600"
                                                aria-label="Ver Detalhes">
                                                <eye-icon class="h-5 w-5" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- Cards para telas menores -->
                        <div class="block lg:hidden space-y-4">
                            <div v-for="evento in eventos" :key="evento.id"
                                class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 border border-gray-200 dark:border-gray-700">
                                <div class="flex justify-between items-center mb-2">
                                    <h3 class="text-sm font-semibold text-gray-700 dark:text-gray-300">Evento ID:
                                        {{ evento.id }}
                                    </h3>
                                    <span :class="{
                                        'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': formatarTexto('status', evento.status) === 'Finalizado',
                                        'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': formatarTexto('status', evento.status) === 'Pendente',
                                        'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': formatarTexto('status', evento.status) === 'Em Atendimento'
                                    }" class="px-3 py-1 rounded-full text-xs font-medium">
                                        {{ formatarTexto('status', evento.status) }}
                                    </span>
                                </div>
                                <div class="text-sm text-gray-700 dark:text-gray-300">
                                    <p><strong>Unidade:</strong> {{ evento.unidade_nome }}</p>
                                    <p><strong>Categoria:</strong> {{ formatarTexto('categoria',
                                        evento.categoria_evento) }}</p>
                                    <p><strong>Data Abertura:</strong> {{ formatarData(evento.data_abertura) }}</p>
                                </div>
                                <button @click="$router.push({ name: 'DetalhesEvento', params: { id: evento.id } })"
                                    class="mt-2 text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-600 text-sm font-medium">
                                    Ver Detalhes
                                </button>
                            </div>
                        </div>
                    </div>            <!-- Mensagem de carregamento -->
            <div v-if="isLoading" class="flex flex-col items-center justify-center min-h-[500px]">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-24 w-24 text-gray-400 animate-spin mb-4" viewBox="0 0 24 24" fill="none">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                </svg>
                <p class="text-xl font-semibold text-gray-600 dark:text-gray-300">Carregando</p>
            </div>
                    <div v-else class="text-center py-8">
                        <p class="text-gray-600 dark:text-gray-300 text-lg">Nenhum evento a exibir :)</p>
                    </div>
                </div>
            </div>


            <!-- Create Event Modal with Improved Responsiveness -->
            <div v-if="isCreateModalOpen"
                class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 dark:bg-opacity-70 p-4">
                <div
                    class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl">
                    <h2 class="text-2xl font-bold mb-6 text-gray-800 dark:text-white">Novo Evento</h2>
                    <form @submit.prevent="createEvento" class="space-y-6">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <!-- Unidade -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Unidade</label>
                                <select v-model="novoEvento.unidade"
                                    @change="fetchEquipamentosPorUnidade(novoEvento.unidade)"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    required>
                                    <option value="" disabled>Selecione a Unidade</option>
                                    <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                                        {{ unidade.nome }}
                                    </option>
                                </select>
                            </div>

                            <!-- Equipamento -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Equipamento
                                </label>
                                <select v-model="novoEvento.equipamento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione o Equipamento</option>
                                    <option v-for="equipamento in equipamentos" :key="equipamento.id"
                                        :value="equipamento.id">
                                        {{ equipamento.nome }}
                                    </option>
                                </select>
                            </div>
                            <!-- Entidade -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Entidade</label>
                                <select v-model="novoEvento.entidade"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione a entidade</option>
                                    <option v-for="ent in entidades" :key="ent.id" :value="ent.id">
                                        {{ ent.nome }}
                                    </option>
                                </select>
                            </div>


                            <!-- Categoria -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Categoria</label>
                                <select v-model="novoEvento.categoria_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    @change="handleCategoriaChange" required>
                                    <option value="preventiva">Manutenção Preventiva</option>
                                    <option value="corretiva">Manutenção Corretiva</option>
                                    <option value="adverso">Evento Adverso</option>
                                </select>
                            </div>

                            <!-- Status -->
                            <div>
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Status</label>
                                <select v-model="novoEvento.status"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    required>
                                    <option value="pendente">Pendente</option>
                                    <option value="em_atendimento">Em Atendimento</option>
                                    <option value="finalizado">Finalizado</option>
                                </select>
                            </div>

                            <!-- Parada Checkbox -->
                            <div class="flex items-center">
                                <input type="checkbox" v-model="novoEvento.is_parada"
                                    class="h-4 w-4 text-blue-600 dark:bg-gray-700 dark:border-gray-600 rounded focus:ring-blue-500" />
                                <label class="ml-2 text-sm text-gray-700 dark:text-gray-300">Parada?</label>
                            </div>

                            <!-- Motivo do Evento -->
                            <div v-if="novoEvento.categoria_evento !== 'preventiva'">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Motivo</label>
                                <select v-model="novoEvento.motivo_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione o Motivo</option>
                                    <option v-for="motivo in motivosEventos" :key="motivo.id" :value="motivo.id">{{
                                        motivo.nome }}</option>
                                </select>
                            </div>

                            <!-- Solução do Evento -->
                            <div v-if="novoEvento.categoria_evento !== 'preventiva'">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Solução</label>
                                <select v-model="novoEvento.solucao_evento"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500">
                                    <option value="" disabled>Selecione a Solução</option>
                                    <option v-for="solucao in solucoesEventos" :key="solucao.id" :value="solucao.id">{{
                                        solucao.nome }}</option>
                                </select>
                            </div>



                            <!-- Descrição -->
                            <div class="col-span-full">
                                <label
                                    class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Descrição</label>
                                <textarea v-model="novoEvento.descricao"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500"
                                    placeholder="Digite a descrição" required rows="4"></textarea>
                            </div>

                            <!-- Datas -->
                            <div>
                                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Data de
                                    Início</label>
                                <input type="date" v-model="novoEvento.data_inicio" :min="dataAtual"
                                    class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                            </div>
                            <div>
                                <label class="block text-sm font-medium mb-2 text-gray-700 dark:text-gray-300">Data
                                    Final</label>
                                <div class="flex items-center gap-4">
                                    <input type="date" v-model="novoEvento.data_final" :disabled="semPrevisaoFinal"
                                        :required="novoEvento.categoria_evento === 'preventiva'"
                                        class="w-full border rounded-lg px-4 py-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-2 focus:ring-blue-500" />
                                    <div class="flex items-center" v-if="novoEvento.categoria_evento !== 'preventiva'">
                                        <input type="checkbox" id="sem-previsao" v-model="semPrevisaoFinal"
                                            class="h-4 w-4 text-blue-600 dark:bg-gray-700 dark:border-gray-600 rounded focus:ring-blue-500" />
                                        <label for="sem-previsao"
                                            class="ml-2 text-sm text-gray-700 dark:text-gray-300">Sem Previsão
                                            Final</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal Actions -->
                        <div class="flex justify-end space-x-3 mt-6">
                            <button type="button" @click="closeCreateModal"
                                class="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white px-6 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors">Cancelar</button>
                            <button type="submit"
                                class="bg-blue-500 dark:bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors">Salvar</button>
                        </div>
                    </form>
                </div>
            </div>

            <!-- Rodapé fixo -->
            <p class="text-center text-sm mt-6 text-gray-500 dark:text-gray-400">
                © 2024 T.I CCDTI. Todos os direitos reservados.
            </p>
        </template>

    </BaseTemplate>
</template>
<script setup>

import BaseTemplate from './BaseTemplate.vue';
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import {
    EyeIcon,
} from 'lucide-vue-next';
const eventos = ref([]);
const entidades = ref([]);
const unidades = ref([]);
const equipamentos = ref([]);
const isCreateModalOpen = ref(false);

const novoEvento = ref({
    unidade: "",
    equipamento: "",
    entidade: "",
    status: "em_atendimento",
    is_parada: false,
    motivo_evento: "",
    solucao_evento: "",
    categoria_evento: "adverso",
    descricao: "",
    data_inicio: "",
    data_final: "",
});



const isLoading = ref(false); // Indica se está carregando dados
const error = ref(null); // Armazena mensagens de erro
const motivosEventos = ref([]);
const semPrevisaoFinal = ref(false);
const solucoesEventos = ref([]);
axios.defaults.headers.common['X-API-KEY'] = sessionStorage.api_token; // Substitua pela sua API Key real



// Fetch Motivos e Soluções
async function fetchMotivosEventos() {
    try {
        const response = await axios.get('/api/v1/motivos-eventos/');
        motivosEventos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar motivos dos eventos:', error);
    }
}

async function fetchSolucoesEventos() {
    try {
        const response = await axios.get('/api/v1/solucoes-eventos/');
        solucoesEventos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar soluções dos eventos:', error);
    }
}

// Buscar eventos
async function fetchEventos() {
    isLoading.value = true;
    try {
        const response = await axios.get('/api/v1/eventos/');
        eventos.value = response.data;
    } catch (err) {
        error.value = 'Erro ao carregar eventos';
        console.error(err);
    } finally {
        isLoading.value = false;
    }
}

// Fetch Unidades
async function fetchUnidades() {
    try {
        const response = await axios.get('/api/v1/unidades/');
        unidades.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar unidades:', error);
    }
}

// Fetch Equipamentos por Unidade
async function fetchEquipamentosPorUnidade(unidadeId) {
    equipamentos.value = []; // Limpa antes de carregar
    if (!unidadeId) return; // Não busca se nenhuma unidade está selecionada

    try {
        const response = await axios.get('/api/v1/equipamentos/', { params: { unidade_id: unidadeId } });
        equipamentos.value = response.data;
    } catch (error) {
        console.error('Erro ao carregar equipamentos:', error);
    }
}

watch(semPrevisaoFinal, (newValue) => {
    if (newValue) {
        novoEvento.value.data_final = null; // Limpa o campo se estiver desativado
    }
});

// Função para formatar texto (capitalize ou mapear)
function formatarTexto(chave, valor) {
    const map = {
        categoria_evento: {
            corretiva: 'Corretiva',
            preventiva: 'Preventiva',
            adverso: 'Adverso',
            outro: 'Outro',
        },
        status: {
            em_atendimento: 'Em Atendimento',
            finalizado: 'Finalizado',
            em_espera: 'Em Espera',
        },
    };

    return map[chave]?.[valor] || valor.charAt(0).toUpperCase() + valor.slice(1).replace('_', ' ');
}




async function fetchEntidades() {
    try {
        const response = await axios.get('/api/v1/entidades/');
        entidades.value = response.data;
    } catch (err) {
        console.error('Erro ao carregar entidades:', err);
    }
}

// Formatar data
function formatarData(data) {
    return new Date(data).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
}



async function createEvento() {
    try {
        // Validação básica
        if (!novoEvento.value.unidade || !novoEvento.value.status) {
            alert("Preencha todos os campos obrigatórios.");
            return;
        }

        // Validação das datas
        if (
            novoEvento.value.data_inicio &&
            novoEvento.value.data_final &&
            new Date(novoEvento.value.data_final) < new Date(novoEvento.value.data_inicio)
        ) {
            alert("A data final não pode ser anterior à data de início.");
            return;
        }

        // Envio dos dados
        const response = await axios.post('/api/v1/eventos/', novoEvento.value);
        eventos.value.push(response.data);
        alert('Evento criado com sucesso!');
        closeCreateModal(); // Fecha o modal
    } catch (error) {
        console.error('Erro ao criar evento:', error);
        alert('Erro ao criar evento. Verifique os dados enviados.');
    }
}


// Abrir e Fechar Modal
function openCreateModal() {
    isCreateModalOpen.value = true;
    resetNovoEvento(); // Reseta campos do formulário
}

function closeCreateModal() {
    isCreateModalOpen.value = false;
}

function handleCategoriaChange() {
    if (novoEvento.value.categoria_evento === 'preventiva') {
        const motivoPreventiva = motivosEventos.value.find(motivo => motivo.nome === 'Manutenção Preventiva Programada');
        const solucaoPreventiva = solucoesEventos.value.find(solucao => solucao.nome === 'Manutenção Preventiva Programada');

        // Use os IDs correspondentes para motivo e solução
        novoEvento.value.motivo_evento = motivoPreventiva ? motivoPreventiva.id : null;
        novoEvento.value.solucao_evento = solucaoPreventiva ? solucaoPreventiva.id : null;

        semPrevisaoFinal.value = false; // Não permite "Sem Previsão"
    } else {
        novoEvento.value.motivo_evento = null;
        novoEvento.value.solucao_evento = null;
    }
}


watch(() => novoEvento.value.status, (newStatus) => {
    if (newStatus === 'pendente') {
        novoEvento.value.data_inicio = ''; // Limpa para evitar inconsistências
    }
});


// Resetar Formulário
function resetNovoEvento() {
    novoEvento.value = {
        unidade: null,
        entidade: null,
        equipamento: null,
        categoria_evento: '',
        descricao: '',
        data_inicio: null,
        data_final: null
    };
    equipamentos.value = []; // Limpa a lista de equipamentos
}

onMounted(async () => {
    document.title = 'Eventos - CCDTI'
    await Promise.all([
        fetchUnidades(),
        fetchEntidades(),
        fetchEquipamentosPorUnidade(),
        fetchMotivosEventos(),
        fetchSolucoesEventos(),
        fetchEventos(),
    ]);
});

</script>



<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
</style>
