<template>
    <div class="not-found-container" :class="{ 'dark-mode': isDarkMode, 'light-mode': !isDarkMode }">
      <div class="error-wrapper">
        <div class="icon-container">
          <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 24 24" fill="none" stroke="var(--primary-color)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-compass">
            <path d="m16.24 7.76-1.804 5.411a2 2 0 0 1-1.265 1.265L7.76 16.24l1.804-5.411a2 2 0 0 1 1.265-1.265z" />
            <circle cx="12" cy="12" r="10" />
          </svg>
        </div>
  
        <div class="error-content">
          <div class="error-code">404</div>
          <div class="error-message">Página Não Encontrada</div>
          <div class="error-description">Parece que você se perdeu na navegação.</div>
  
          <div class="action-links">
            <a @click="handleLogout" class="home-link">
              Voltar ao Início
              <span class="arrow">→</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'NotFound',
    data() {
      return {
        isDarkMode: true, // Altere dinamicamente com base no tema do sistema, se necessário
      };
    },
    setup() {
      const router = useRouter();
  
      const handleLogout = () => {
        sessionStorage.removeItem('api_token');
        sessionStorage.removeItem('token_expiry');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('firstname');
        sessionStorage.removeItem('lastname');
        sessionStorage.removeItem('user_groups');
        router.push({ name: 'Login' });
      };
  
      return { handleLogout };
    },
  };
  </script>
  
  <style scoped>
  /* Contêiner principal */
  .not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    background-color: var(--bg-color);
    transition: background-color 0.3s ease;
  }
  
  /* Wrapper do erro */
  .error-wrapper {
    max-width: 500px;
    width: 100%;
  }
  
  /* Ícone do erro */
  .icon-container {
    margin-bottom: 20px;
  }
  
  /* Código de erro */
  .error-code {
    font-size: 4rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  /* Mensagem de erro */
  .error-message {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  /* Descrição do erro */
  .error-description {
    font-size: 1rem;
    color: var(--secondary-text-color);
    margin-bottom: 20px;
    transition: color 0.3s ease;
  }
  
  /* Links de ação */
  .action-links {
    display: flex;
    justify-content: center;
  }
  
  .home-link {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
  }
  
  .home-link:hover {
    color: var(--link-hover-color);
    transform: scale(1.05);
  }
  
  .home-link .arrow {
    margin-left: 5px;
    font-size: 1.2rem;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .error-code {
      font-size: 3rem;
    }
  
    .error-message {
      font-size: 1.25rem;
    }
  
    .error-description {
      font-size: 0.9rem;
    }
  
    .home-link {
      font-size: 0.9rem;
    }
  }
  
  /* Modo Claro */
  .light-mode {
    --bg-color: #ffffff;
    --primary-color: #ff4d4d;
    --text-color: #333333;
    --secondary-text-color: #666666;
    --link-color: #007bff;
    --link-hover-color: #0056b3;
  }
  
  /* Modo Escuro */
  .dark-mode {
    --bg-color: #1a1a1a;
    --primary-color: #ff6b6b;
    --text-color: #f5f5f5;
    --secondary-text-color: #cccccc;
    --link-color: #80bdff;
    --link-hover-color: #5aa3ff;
  }
  </style>
  