// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginScreen from '../components/LoginScreen.vue';
import RegisterScreen from '../components/RegisterScreen.vue';
import ResetPassword from '../components/ResetPassword.vue';
import EventosList from '../components/EventosList.vue';
import DetalhesEventos from '../components/DetalhesEventos.vue';
import MetricsView from '../components/MetricsView.vue';
// Importar o componente de página 404
import NotFound from '../components/NotFound.vue';
import Offline from '../components/OfflineScreen.vue';
import DashboardEquipamentos from '../components/DashboardEquipamentos.vue';
import DashboardInstancias from '@/components/DashboardInstancias.vue';

const API_URL = process.env.VUE_APP_API_URL;

// Função para validar o token com o backend
async function validateTokenWithBackend(token) {
  try {
    const response = await fetch(`${API_URL}/api/v1/auth/validate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // Ajuste se necessário
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    if (!response.ok || !data.valid) {
      throw new Error(data.message || 'Token inválido');
    }

    if (data.groups) {
      sessionStorage.setItem('user_groups', JSON.stringify(data.groups));
    }

    return true;
  } catch (error) {
    return false;
  }
}

// Função de autenticação
async function isAuthenticated() {
  const token = sessionStorage.getItem('api_token');
  const expiry = sessionStorage.getItem('token_expiry');

  if (!token || !expiry) {
    return false;
  }

  const now = Date.now();
  if (now > parseInt(expiry, 10)) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
    return false;
  }

  const isValid = await validateTokenWithBackend(token);

  if (!isValid) {
    sessionStorage.removeItem('api_token');
    sessionStorage.removeItem('token_expiry');
  }

  return isValid;
}

// Função para obter os grupos do usuário
function getUserGroups() {
  const groups = sessionStorage.getItem('user_groups');
  return groups ? JSON.parse(groups) : [];
}

// Adicionar a rota 404 ao final do array de rotas
const routes = [
  { path: '/', name: 'Login', component: LoginScreen },
  { path: '/register', name: 'Register', component: RegisterScreen },
  { path: '/reset-password', name: 'ResetPassword', component: ResetPassword },
  {
    path: '/eventos',
    name: 'EventosList',
    component: EventosList,
    meta: { requiresAuth: true, roles: ['manager'] },
  },
  {
    path: '/eventos/:id',
    name: 'DetalhesEvento',
    component: DetalhesEventos,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/dashboardequipamentos',
    name: 'DashboardEquipamentos',
    component: DashboardEquipamentos,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboardinstancias',
    name: 'DashboardInstancias',
    component: DashboardInstancias,
    meta: { requiresAuth: true, roles: ['manager', 'admin'] },
  },
  {
    path: '/metrics/:id',
    name: 'Metrics',
    component: MetricsView,
    meta: { requiresAuth: true, roles: ['manager', 'admin'] },
    props: true, // Garante que o `id` seja passado como prop para o componente
  },
  { path: '/offline', name: 'Offline', component: Offline },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
];


// Criação do roteador
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    // Verifica se o navegador está offline
    if (!navigator.onLine) {
      if (to.name !== 'Offline') {
        // Redireciona para a tela "Offline" e preserva a rota de destino
        return next({
          name: 'Offline',
          query: { redirect: to.fullPath }, // Salva a rota original na query
        });
      }
    } else if (to.name === 'Offline') {
      // Se estiver online e na rota "Offline", redireciona para a página principal
      const redirect = to.query.redirect || '/';
      return next(redirect);
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // Verifica se a rota não exige autenticação
    if (!requiresAuth) {
      return next();
    }

    const isLoggedIn = await isAuthenticated();

    if (!isLoggedIn) {
      if (to.name === 'Login') {
        // Evita redirecionamento para login se já estamos na tela de login
        return next();
      }
      return next({ name: 'Login', query: { accessDenied: true } });
    }

    const roles = to.meta.roles || [];
    const userGroups = getUserGroups();

    if (roles.length > 0 && !roles.some(role => userGroups.includes(role))) {
      return next({
        name: 'DashboardEquipamentos',
        query: { unauthorized: true },
      });
    }

    next();
  } catch (error) {
    next({ name: 'Login', query: { error: true } });
  }
});








export default router;
