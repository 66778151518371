<template>
  <div :class="['min-h-screen flex', isDark ? 'dark' : '']">
    <!-- Background gradient refinado -->
    <div class="fixed inset-0 bg-gradient-to-br from-blue-50 via-gray-100 to-gray-200 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 transition-colors duration-500 ease-in-out"></div>
    
    <!-- Left section - visible only on desktop -->
    <div class="relative hidden lg:flex lg:w-1/2 items-center justify-center overflow-hidden">
      <!-- Animated background com padrão refinado -->
      <div :class="['absolute inset-0', isDark ? 'bg-gray-900' : 'bg-blue-600']">
        <div class="absolute inset-0 opacity-40">
          <div :class="['absolute w-[600px] h-[600px] -top-32 -left-20 rounded-full mix-blend-multiply filter blur-3xl animate-blob', isDark ? 'bg-blue-800' : 'bg-blue-400']"></div>
          <div :class="['absolute w-[600px] h-[600px] -bottom-32 -right-20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000', isDark ? 'bg-purple-800' : 'bg-purple-400']"></div>
          <div :class="['absolute w-[600px] h-[600px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000', isDark ? 'bg-indigo-800' : 'bg-indigo-400']"></div>
        </div>
        <!-- Padrão de grade decorativo -->
        <div class="absolute inset-0 opacity-10 bg-grid-white"></div>
      </div>
      
      <!-- Content com layout melhorado -->
      <div class="relative z-10  text-white max-w-xl">
        <h1 class="text-5xl font-bold mb-8 animate-fade-in leading-tight">
          Bem-vindo ao
          <span >DASHBOARD</span> CCDTI
        </h1>
        <p class="text-xl leading-relaxed mb-12 animate-fade-in animation-delay-300 opacity-90">
        </p>
        <div class="grid grid-cols-3 gap-8 animate-fade-in animation-delay-600">
          <div class="flex flex-col items-center space-y-3 p-4 rounded-xl bg-white/10 backdrop-blur-sm">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <span class="font-medium">Seguro</span>
          </div>
          <div class="flex flex-col items-center space-y-3 p-4 rounded-xl bg-white/10 backdrop-blur-sm">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
            </svg>
            <span class="font-medium">Rápido</span>
          </div>
          <div class="flex flex-col items-center space-y-3 p-4 rounded-xl bg-white/10 backdrop-blur-sm">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            <span class="font-medium">Confiável</span>
          </div>
        </div>
      </div>
    </div>

<!-- Right section -->
<div class="relative w-full lg:w-1/2 h-screen flex items-center justify-center p-4 lg:p-8 overflow-hidden">
  <div class="relative w-full max-w-md bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-2xl shadow-2xl transition-colors duration-500 ease-in-out p-8">
    <!-- Botão de tema com efeito hover -->
    <button
      @click="toggleDarkMode"
      class="absolute top-6 right-6 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300 transition-all duration-300 ease-in-out"
    >
      <SunIcon v-if="isDark" class="w-5 h-5" />
      <MoonIcon v-else class="w-5 h-5" />
    </button>

    <!-- Logo do Sistema com animação -->
    <div class="flex justify-center mt-6 mb-3">
      <img 
        :src= LogoDark  
        alt="Logo RIS" 
        class="" 
      />
    </div>

    <!-- Título com gradiente -->
    <h2 class="text-2xl font-bold text-center mb-4 bg-gradient-to-r from-blue-600 to-blue-400 dark:from-blue-400 dark:to-blue-300 bg-clip-text text-transparent">
      Acessar o Dashboard
    </h2>
    <p class="text-center text-gray-600 dark:text-gray-400 mb-6">
      Insira suas credenciais para acessar
    </p>

    <!-- Mensagens de erro ou sucesso -->
    <transition name="fade">
      <div v-if="errorMessage" class="flex items-center p-4 mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-300 rounded">
        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <span>{{ errorMessage }}</span>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="successMessage" class="flex items-center p-4 mb-4 bg-green-100 border-l-4 border-green-500 text-green-700 dark:bg-green-900 dark:border-green-700 dark:text-green-300 rounded">
        <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
        </svg>
        <span>{{ successMessage }}</span>
      </div>
    </transition>

    <!-- Formulário com espaçamento adequado -->
    <form @submit.prevent="handleSubmit" class="space-y-4 mb-6">
      <div>
        <label for="username" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Usuário ou E-mail</label>
        <input
          id="username"
          v-model="username"
          type="text"
          required
          class="w-full px-4 py-3 mt-1 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-300"
          placeholder="Digite seu usuário ou e-mail"
        />
      </div>
      
      <div>
        <label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-300">Senha</label>
        <input
          id="password"
          v-model="password"
          type="password"
          required
          class="w-full px-4 py-3 mt-1 rounded-lg border border-gray-300 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 transition-all duration-300"
          placeholder="Digite sua senha"
        />
      </div>

      <!-- Botão de Login -->
      <button
        type="submit"
        class="w-full py-3 px-4 bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="isLoading"
      >
        <span v-if="isLoading" class="flex items-center justify-center">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Aguarde...
        </span>
        <span v-else>Entrar</span>
      </button>
    </form>
 

    <!-- Links com efeito hover -->
    <div class="flex justify-between items-center mt-8">
      <button 
        @click="handleForgotPassword" 
        class="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-300"
      >
        Esqueci minha senha
      </button>
      <button 
        @click="handleRegister" 
        class="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-300 transition-colors duration-300"
      >
        Cadastrar-se
      </button>
        </div>
        <!-- Rodapé fixo -->
        <p class="text-center text-sm mt-6 text-gray-500 dark:text-gray-400">
          © 2024 T.I CCDTI. Todos os direitos reservados.
        </p>
      </div>

      <div v-if="showInstallMessage" class="fixed inset-0 z-50 flex items-end justify-center pointer-events-none">
  <div class="android-install-popup w-full max-w-md mx-4 mb-8 pointer-events-auto">
    <div class="bg-white dark:bg-gray-800 rounded-xl shadow-2xl p-6 relative">
      <div class="absolute -top-2 right-8 w-0 h-0 
        border-l-[20px] border-r-[20px] border-l-transparent 
        border-r-transparent border-b-[20px] border-b-white dark:border-b-gray-800">
      </div>
      <div class="flex items-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6 mr-3 text-blue-600">
          <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
          <polyline points="16 6 12 2 8 6"/>
          <line x1="12" x2="12" y1="2" y2="15"/>
        </svg>
        <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Instalar Aplicativo</h2>
      </div>
      <p class="text-sm text-gray-600 dark:text-gray-400 mb-4">
        Para instalar o aplicativo, toque no botão 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="inline-block w-4 h-4 mx-1 text-blue-600">
          <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
          <polyline points="16 6 12 2 8 6"/>
          <line x1="12" x2="12" y1="2" y2="15"/>
        </svg>
        e selecione "Adicionar à Tela de Início"
      </p>
      <button 
        @click="showInstallMessage = false" 
        class="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Entendi
      </button>
    </div>
  </div>
</div>

<div v-if="showAndroidInstallMessage" class="fixed inset-0 z-50 flex items-end justify-center pointer-events-none">
 <div class="android-install-popup w-full max-w-md mx-4 mb-8 pointer-events-auto">
   <div class="bg-white dark:bg-gray-800 rounded-xl shadow-2xl p-6 relative">
     <div class="absolute -top-2 right-8 w-0 h-0 
       border-l-[20px] border-r-[20px] border-l-transparent 
       border-r-transparent border-b-[20px] border-b-white dark:border-b-gray-800">
     </div>
     <div class="flex items-center mb-4">
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-6 h-6 mr-3 text-green-600">
         <circle cx="12" cy="12" r="1"/>
         <circle cx="12" cy="5" r="1"/>
         <circle cx="12" cy="19" r="1"/>
       </svg>
       <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Instalar Aplicativo</h2>
     </div>
     <p class="text-sm text-gray-600 dark:text-gray-400 mb-4">
       Toque nos 3 pontinhos 
       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="inline-block w-4 h-4 mx-1 text-green-600">
         <circle cx="12" cy="12" r="1"/>
         <circle cx="12" cy="5" r="1"/>
         <circle cx="12" cy="19" r="1"/>
       </svg>
       e selecione "Adicionar à Tela Inicial"
     </p>
     <button 
       @click="showAndroidInstallMessage = false" 
       class="w-full py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
     >
       Entendi
     </button>
   </div>
 </div>
</div>

</div>

    </div>

</template>

<script setup>
import { ref, onMounted, onUnmounted} from 'vue'
import { useRouter } from 'vue-router' // Importa o useRouter
import { SunIcon, MoonIcon } from 'lucide-vue-next'
import LogoDark from '@/assets/LogoDark.png';

const router = useRouter() // Inicializa o router

const username = ref('')
const password = ref('')
const firstname = ref('') // Para exibir o nome no frontend
const lastname = ref('') // Para exibir o sobrenome no frontend
const isLoading = ref(false)
const errorMessage = ref('')
const successMessage = ref('')
const isDark = ref(false)
const API_URL = process.env.VUE_APP_API_URL;


// Alterna o modo claro/escuro
const toggleDarkMode = () => {
  isDark.value = !isDark.value
  localStorage.setItem('darkMode', isDark.value)
  document.documentElement.classList.toggle('dark', isDark.value)
}

// Função para obter o CSRF token do Django
const getCsrfToken = async () => {
  try {
    const response = await fetch(`${API_URL}/csrf-token/`, {
      method: 'GET',
      credentials: 'include',
    });
    if (!response.ok) throw new Error('Erro ao obter o CSRF token');
    const data = await response.json();
    return data.csrfToken;
  } catch (error) {
    console.error('Erro ao obter CSRF token:', error);
    throw error;
  }
};

// Função para autenticação
const handleSubmit = async () => {
  errorMessage.value = '';
  successMessage.value = '';
  isLoading.value = true;

  try {
    const csrfToken = await getCsrfToken();

    const response = await fetch(`${API_URL}/api/v1/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify({
        username: username.value,
        password: password.value,
      }),
      credentials: 'include',
    });




    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Erro ao autenticar');
    }

    const token = data.api_key;
    const userFirstname = data.firstname;
    const userLastname = data.lastname;
    const userGroups = data.groups;
    const tokenExpiry = data.token_expiry || 3600; // Exemplo: Tempo padrão de 1 hora

    // Armazenando dados no sessionStorage
    sessionStorage.setItem('api_token', token);
    sessionStorage.setItem('username', username.value);
    sessionStorage.setItem('firstname', userFirstname);
    sessionStorage.setItem('lastname', userLastname);
    sessionStorage.setItem('user_groups', JSON.stringify(userGroups));
    sessionStorage.setItem('token_expiry', Date.now() + tokenExpiry * 1000);

    // Atualizando o frontend
    firstname.value = userFirstname;
    lastname.value = userLastname;

    successMessage.value = `Bem-vindo(a), ${userFirstname} ${userLastname}!`;

    // Redirecionamento baseado em grupos do usuário
    if (userGroups.includes('admin')) {
      router.push({ name: 'AdminDashboard' });
    } else if (userGroups.includes('manager')) {
      router.push({ name: 'DashboardEquipamentos' });
    } else {
      router.push({ name: 'EventosList' });
    }
  } catch (error) {
    console.error('Erro durante a autenticação:', error.message);
    errorMessage.value = error.message || 'Erro ao conectar com o servidor. Tente novamente mais tarde.';
  } finally {
    isLoading.value = false;
  }
};




onMounted(() => {
  document.documentElement.classList.add('fixed-body'); // Adiciona a classe para impedir rolagem
});

onUnmounted(() => {
  document.documentElement.classList.remove('fixed-body'); // Remove a classe ao desmontar o componente
});

const handleForgotPassword = () => {
  router.push({ name: 'ResetPassword' }) // Navega para StudiesList usando o router
}

const handleRegister = () => {
  router.push({ name: 'Register' }) // Navega para StudiesList usando o router
}

// iOS Detection Methods
const isIos = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

const isInStandaloneMode = () => {
  return ('standalone' in navigator) && (navigator.standalone);
}

// Add a ref for install message visibility
const showInstallMessage = ref(false)

onMounted(() => {
  // Check for iOS and standalone mode
  if (isIos() && !isInStandaloneMode()) {
    showInstallMessage.value = true;
    console.log('iOS device detected, not in standalone mode');
  }
})


const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
}

const showAndroidInstallMessage = ref(false)

onMounted(() => {
  if (isAndroid() && !window.matchMedia('(display-mode: standalone)').matches) {
    showAndroidInstallMessage.value = true;
    console.log('Android device detected, not in standalone mode');
  }
})

onMounted(() => {
  document.body.style.overflow = 'hidden'; // Remove a rolagem no corpo
  document.body.style.position = 'fixed'; // Fixa o conteúdo
  document.body.style.width = '100%'; // Impede deslocamentos horizontais
  document.body.style.height = '100%'; // Fixa a altura
});

onUnmounted(() => {
  document.body.style.overflow = ''; // Restaura o comportamento padrão ao desmontar
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.height = '';
});

onMounted(() => {
  document.title = 'Dashboard - CCDTI'
  isDark.value = localStorage.getItem('darkMode') === 'true'
  document.documentElement.classList.toggle('dark', isDark.value)

  // Verifica se há uma tentativa de acesso a uma rota protegida sem autenticação
  if (router.currentRoute.value.query.accessDenied) {
    errorMessage.value = 'Acesso negado. Por favor, faça login para continuar.'

    // Remove o parâmetro da URL após exibir a mensagem
    router.replace({ query: {} })
  }
})

</script>

<style scoped>

.ios-install-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#app {
  height: 100vh;
  overflow: hidden;
  display: flex;
}

h-screen {
  height: 100vh !important;
}

.fixed-body {
  position: fixed; /* Garante que a posição fique fixa */
  width: 100%;
  height: 100%;
  overflow: hidden; /* Impede qualquer tipo de rolagem */
}

h-screen {
  height: 100vh;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Mantendo suas animações existentes e adicionando novas */
.animate-blob {
  animation: blob 10s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-fade-in {
  animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

/* Padrão de grade decorativo */
.bg-grid-white {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
                    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: 20px 20px;
}

@keyframes blob {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  25% {
    transform: translate(20px, -50px) scale(1.1);
  }
  50% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  75% {
    transform: translate(40px, 40px) scale(1.05);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>